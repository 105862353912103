<template>
  <div class="hello">
    <div class="top">
      Welcome to mygirl Where Style Meets Innovation
    </div>
    <div class="main">
      <div class="logoBox">
        <div >
          <a class="logo" href="http://mygirl.tech" target="_blank" rel="noopener noreferrer">
          <img src="../assets/mygirl-logo.jpg" alt="">
           <span>MyGirl</span>
          </a>
        </div>
        <div class="text">X</div>
        <div >
          <a class="ntv" href="https://naughtytrove.com/" target="_blank" rel="noopener noreferrer">
          <img src="../assets/NaughtyTrove.png" alt="">
          <span>NaughtyTrove</span>
        </a>
        </div>
      </div>
      <div class="center">
        <div class="imgBox">
          <div class="bigImg"> 
            <img :src="pic" alt="">
          </div>
          <div class="swiperBox">
            <div class="swiper-container" id="swiper1">
              <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="item in picList" :key="item.id">
                  <div class="mask">
                    <img :src="item"  alt="">
                  </div>
                </div>
              </div>
              <div class="swiper-button-next" v-show="false"></div>
              <div class="swiper-button-prev" v-show="false"></div>
            </div>
            <div class="swiper-pagination"></div>
             <div class="swiper-left" @click="swiperleft">
              <img src="../assets/left.png" alt="">
             </div>
             <div class="swiper-right" @click="swiperight">
              <img src="../assets/right.png" alt="">
             </div>
          </div>
        </div>
        <div class="styleBox">
          <div class="text1">
            Teaser Style Lace Breathable Bra With matching Panties, Gartwe And Stockings
          </div>
          <div class="text2">
            $79.99<span>$99.99</span>
          </div>
          <div class="text7">
            <div>
                  "Oh don't be a tease..." - but really, could you be even more of a tease? The extended series of the Teaser style bra & panty set, is when you add on a matching set of Garters and Stockings.<br>
                  We've all heard this before, but really, doesn't everyone fancy a tease?<br><br>
                  Why this series is called the "Teaser Style" is mainly due to its design. The brassiere covers the midriff partially in a gorgeous lace embroidered design. We've designed the bra to be a 3/4 cup with half of that being lace, which will accentuate your assets.<br>
                  Plus, we've included a little design feature right in the middle of the bra where you'll see a small hole covered with a nice gem accessory, to really add that sense of elegance to the bra.<br>
                  The best part? For each set, we've opted for a flirty butt hugging Brazilian style, translucent lace panty to match the top.<br>
                  Available in Red, White and Black!<br><br>
                  👄 Perfect For: Daily Wear & Bedroom Fun💦<br>
                  Naughty Rating: 4.9/5
                </div>
          </div>
          <div class="text8">
            Customer Submissions:
          </div>
          <div class="text3">
            CUP SIZE
          </div>
          <div class="select">
            <el-select v-model="value" filterable placeholder="请选择" :popper-append-to-body="false">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <div class="text4">
            Color
          </div>
          <div class="colorBox">
            <div class="circle" v-for="item in colorList" :key="item.id" >
                <div class="color" :style="{ background:item}" @click="choseColor"></div>
            </div>
          </div>
          <div class="text9">
            CUP SIZE
          </div>
          <div class="radio">
            <el-radio-group v-model="radio" v-for="item in options" :key="item.id">
              <el-radio-button :label="item.value">{{ item.label }}</el-radio-button>
              <!-- <el-radio :label="6">备选项</el-radio>
              <el-radio :label="9">备选项</el-radio> -->
            </el-radio-group>
          </div>
          <div class="text5">
            Qty
          </div>
          <div class="btnBox">
            <div class="count">
              <el-input-number  v-model="num" :precision="0" :step="1" :max="9999" :min="1"></el-input-number>
            </div>
            <div class="btn">
              <el-button @click="toBuy">Buy Now</el-button>
            </div>
           </div>
           <div class="pngBox">
            <div class="line">
              <img src="../assets/twitter.png" alt="">
            </div>
            <div class="line">
              <img src="../assets/facebook.png" alt="">
            </div>
            <div class="line">
              <img src="../assets/entypo.png" alt="">
            </div>
            <div class="line">
              <img src="../assets/Vector.png" alt="">
            </div>
           </div>
          <div class="des">
            <el-collapse accordion v-model="activeName" @change="change">
              <el-collapse-item name="1">
                <template slot="title">
                  Description
                </template>
                <div>
                  "Oh don't be a tease..." - but really, could you be even more of a tease? The extended series of the Teaser style bra & panty set, is when you add on a matching set of Garters and Stockings.<br>
                  We've all heard this before, but really, doesn't everyone fancy a tease?<br><br>
                  Why this series is called the "Teaser Style" is mainly due to its design. The brassiere covers the midriff partially in a gorgeous lace embroidered design. We've designed the bra to be a 3/4 cup with half of that being lace, which will accentuate your assets.<br>
                  Plus, we've included a little design feature right in the middle of the bra where you'll see a small hole covered with a nice gem accessory, to really add that sense of elegance to the bra.<br>
                  The best part? For each set, we've opted for a flirty butt hugging Brazilian style, translucent lace panty to match the top.<br>
                  Available in Red, White and Black!<br><br>
                  👄 Perfect For: Daily Wear & Bedroom Fun💦<br>
                  Naughty Rating: 4.9/5
                </div>
              </el-collapse-item>
            </el-collapse>
          </div>
          <div class="iconBox">
            <div class="text6">
              Share
            </div>
            <div class="icon">
              <img src="../assets/icon-facebook.png" alt="">
            </div>
            <div class="icon">
              <img src="../assets/icon-twitter.png" alt="">
            </div>
            <div class="icon">
              <img src="../assets/icon-instagram.png" alt="">
            </div>
            <div class="icon">
              <img src="../assets/icon-pinterest.png" alt="">
            </div>
            <div class="icon">
              <img src="../assets/icon-envelope.png" alt="">
            </div>

          </div>
        </div>
      </div>
      <div class="message">
        <div class="left">
          <div class="txt1">
            Subscribe To Our Newsletter
          </div>
          <div class="txt2">
            Be the first to know about our biggest and best sales.We'll never send more than one email a month.
          </div>
          <!-- <el-button>
            
            <a href="https://cdn.forms-content-1.sg-form.com/80e0e1c7-73b6-11ee-b27f-7ac36d13a67f" target="myiframe">Subscribe</a>
          </el-button> -->
          <el-popover
            :width="width"
            height="550"
            placement="right"
            trigger="click">
            <iframe :src="src" width="100%" scrolling="auto" height="650px" rameborder="0" name="myiframe"></iframe>
            <el-button slot="reference">
              <!-- <a href="https://cdn.forms-content-1.sg-form.com/80e0e1c7-73b6-11ee-b27f-7ac36d13a67f" target="myiframe">Subscribe</a> -->
              Subscribe
            </el-button>
          </el-popover>
          <!-- <el-input
            placeholder="Email Address"
            v-model="input">
            <el-button slot="suffix" class="el-input__icon ">Subscribe</el-button>
          </el-input> -->
        </div>
        <div class="right">
          <img src="../assets/msg.png" alt="">
        </div>

      </div>
      <div class="bottom">
       <div class="end">
        <div class="logo">
          <img src="../assets/mygirl-logo.jpg" alt="">
        </div>
        <span>MyGirl</span>
       </div>
       <div class="end2">
        I wish I could leap right out of your screen in my highest heels and do a little turn for you in my favorite barely-there outfits. You always make me feel so beautiful when you look at me like that.
       </div>
      </div>
    </div>
    <footer>
        <div class="reserved">
          © 2023 MyGirl.All Rights Reserved
        </div>
        <div class="Box">
          <img src="../assets/Frame.png" alt="">
        </div>
    </footer>
  </div>
</template>

<script>
import Swiper from 'swiper'; // 注意引入的是Swiper
// import 'swiper/css/swiper.min.css' 
export default {
  name: 'HelloWorld',
  props: {
    msg: String
  },
  data() {
      return {
        options: [
        //   {
        //   value: '70A',
        //   label: '70A'
        // }
        // , {
        //   value: '70B',
        //   label: '70B'
        // },
        // {
        //   value: '70C',
        //   label: '70C'
        // },
        //  {
        //   value: '70D',
        //   label: '70D'
        // }
        // , {
        //   value: '75A',
        //   label: '75A'
        // }
        // , {
        //   value: '75B',
        //   label: '75B'
        // },
         {
          value: '75C',
          label: '75C'
        }
        // , {
        //   value: '75D',
        //   label: '75D'
        // }
        // , {
        //   value: '80A',
        //   label: '80A'
        // }
        // , {
        //   value: '80B',
        //   label: '80B'
        // }
        // , {
        //   value: '80C',
        //   label: '80C'
        // }
        // , {
        //   value: '80D',
        //   label: '80D'
        // }
        // , {
        //   value: '85B',
        //   label: '85B'
        // }
        // , {
        //   value: '85C',
        //   label: '85C'
        // }
        // , {
        //   value: '85D',
        //   label: '85D'
        // }
        // , {
        //   value: '90C',
        //   label: '90C'
        // }
        // , {
        //   value: '90D',
        //   label: '90D'
        // }
        // , {
        //   value: '95C',
        //   label: '95C'
        // }
        // , {
        //   value: '95D',
        //   label: '95D'
        // }
      ],
        value: '75C',
        radio:"75C",
        input:'',
        swiper:null,
        activeName:"0",
        showswiper:true,
        Mark:0,
        index:0,
        size:"70A",
        color:"black",
        colorList:[
          "black","white","red"
        ],
        num:1,
        pic:require('@/assets/clothes1-black.png'),
        width:600,
        src:'',
        picList:[
        require('@/assets/clothes1-black.png'),
        require('@/assets/clothes1-white.png'),
        require('@/assets/clothes1-red.png')
       
       
        ],
        picListBlack:[
        require('@/assets/clothes1-black.png'),
        require('@/assets/clothes2-black.png'),
        require('@/assets/clothes3-black.png'),
        require('@/assets/clothes4-black.png'),
        require('@/assets/clothes5-black.png'),
        require('@/assets/clothes6-black.png'),
        require('@/assets/clothes7-black.png'),
        require('@/assets/clothes8-black.png'),
        require('@/assets/clothes9-black.png'),
        require('@/assets/clothes10-black.png'),
        require('@/assets/clothes11-black.png'),
        require('@/assets/clothes12-black.png'),
        require('@/assets/clothes13-black.png'),
        require('@/assets/clothes14-black.png'),
        require('@/assets/clothes15-black.png'),
        require('@/assets/clothes16-black.png'),
       
        ],
        picListWhite:[
        require('@/assets/clothes1-white.png'),
        require('@/assets/clothes2-white.png'),
        require('@/assets/clothes3-white.png'),
        require('@/assets/clothes4-white.png'),
        require('@/assets/clothes5-white.png'),
        require('@/assets/clothes6-white.png'),
        require('@/assets/clothes7-white.png'),
        require('@/assets/clothes8-white.png'),
        require('@/assets/clothes9-white.png'),
        require('@/assets/clothes10-white.png'),
        require('@/assets/clothes11-white.png'),
        require('@/assets/clothes12-white.png'),
        require('@/assets/clothes13-white.png'),
        require('@/assets/clothes14-white.png'),
        require('@/assets/clothes15-white.png'),
        require('@/assets/clothes16-white.png'),
       
        ],
        picListRed:[
        require('@/assets/clothes1-red.png'),
        require('@/assets/clothes2-red.png'),
        require('@/assets/clothes3-red.png'),
        require('@/assets/clothes4-red.png'),
        require('@/assets/clothes5-red.png'),
        require('@/assets/clothes6-red.png'),
        require('@/assets/clothes7-red.png'),
        require('@/assets/clothes8-red.png'),
        require('@/assets/clothes9-red.png'),
        require('@/assets/clothes10-red.png'),
        require('@/assets/clothes11-red.png'),
        require('@/assets/clothes12-red.png'),
        require('@/assets/clothes13-red.png'),
        require('@/assets/clothes14-red.png'),
        require('@/assets/clothes15-red.png'),
        require('@/assets/clothes16-red.png'),
       
        ],

      }
  },
  created(){
    // console.log('link',window.location.href)
    let href=window.location.href
    let index = href.lastIndexOf("=")
    // let indexss2 = this.pic.indexOf(".")
    let resolves = href.substring(index+1,index+3)-1;
    if(resolves<this.picListBlack.length&&resolves>=0){
          this.picList=[
      this.picListBlack[resolves],
      this.picListWhite[resolves],
      this.picListRed[resolves]
    ] 
    }

    console.log(index,resolves);
    // this.showswiper=true
    // this.getSwiper();
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
       this.mark=1
      }else{
        this.mark=0
      }
  },
  mounted(){
    this.src='https://cdn.forms-content-1.sg-form.com/80e0e1c7-73b6-11ee-b27f-7ac36d13a67f'
    var arr=document.querySelectorAll('.circle')
    arr[0].classList.add("white")
    this.getSwiper();
    this.resize()
    window.scrollTo(0, 0);
    // this.getSwiper();
    
    window.onresize=()=>{
    this.resize()
    //  this.picList=[
    //     require('@/assets/image6.png'),
    //     require('@/assets/image2.png'),
    //     require('@/assets/image3.png'),
    //     require('@/assets/image4.png'),
    //     require('@/assets/image5.png'),
    //     ]
    // this.swiper.destroy()
    // this.swiper.init()
    }
    // location.reload();
    // this.swiper=null 
//     this.showswiper=false
//     this.showswiper=false
//     this.$nextTick(() => {
//                 // 在 DOM 中添加 my-component 组件
//                 // this.showswiper=true
//                 this.swiper=null
//                 this.getSwiper();
//                 this.showswiper=true
//             });
//     // setTimeout(()=>{
//     //   this.showswiper=true
//     //   this.getSwiper();
//     // },1000)
//     console.log(this.showswiper);
//     // this.showswiper=true
//     // this.getSwiper();
// }
  },
  methods:{
    resize(){
      this.activeName='0'
      // if()
       var main=document.querySelector(".main")
       if(main.offsetHeight==2340){

        main.style.height='2060px'
        }else if(main.offsetHeight==2330){
        main.style.height='1960px'
        }
        if(main.offsetWidth<402){
          main.style.height='3430px'
          this.width=310
          this.size=this.value
        }else if(main.offsetWidth>=402&&main.offsetWidth<=683){
          main.style.height='3230px'
          this.width=380
          this.size=this.value
        }else if(main.offsetWidth>683&&main.offsetWidth<=768){
          main.style.height='1850px'
          this.width=600
          this.size=this.radio
        }else if(main.offsetWidth<=1440&&main.offsetWidth>768){
          main.style.height='1960px'
          this.width=600
          this.size=this.radio
        }else{
          this.width=600
          this.size=this.radio
          main.style.height='2060px'
        }
      var a
      if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        a=1
      }else{
        a=0
      }
      if(this.mark!=a){
        location.reload()
      }
    },
    change(){
      var main=document.querySelector(".main")
      // var 
      console.log(main.offsetHeight);
      if(this.activeName==1){
        if(main.offsetHeight==2060){
          main.style.height='2341px'
        }else if(main.offsetHeight==1960){
          main.style.height='2440px'
        }else if(main.offsetHeight==1850){
          main.style.height='2339px'
        }
        // if(main.style.height)
      }else{
        if(main.offsetHeight==2440){

          main.style.height='1960px'
        }else if(main.offsetHeight==2330){
          main.style.height='1960px'
        }else if(main.offsetHeight==2339){
          main.style.height='1850px'
        }else if(main.offsetHeight==2341){
          main.style.height='2060px'
        }
        else{
          main.style.height='3230px'
        }
      }
      console.log(this.activeName);
    },
    swiperleft(){
      document
        .querySelector(".swiper-button-prev")
        .click();
    },
    swiperight(){
      document
        .querySelector(".swiper-button-next")
        .click();
    },
    choseColor(item){
      var arr=document.querySelectorAll('.circle')
      for(let i=0;i<arr.length;i++){
        if(arr[i].classList.contains("white")){
          arr[i].classList.remove("white")
        }
      }
      this.color=item.target.style.background
      if(item.target.style.background=='black'){
        this.pic=this.picList[0]
      }else if(item.target.style.background=='white'){
        this.pic=this.picList[1]
      }else{
        this.pic=this.picList[2]
      }
      // console.log(item.target.style.background,this.index);
      item.target.parentElement.classList.add("white")
    },
    toBuy(){
      var linkBlack=`https://naughtytrove.myshopify.com/cart/39309188694148:${this.num}?channel=buy_button`
      var linkWhite=`https://naughtytrove.myshopify.com/cart/39309189480580:${this.num}?channel=buy_button`
      var linkRed=`https://naughtytrove.myshopify.com/cart/39309189578884:${this.num}?channel=buy_button`
      // var href=link+this.num+this.color+this.size+"character="+(this.index+1)
      let link
      if(this.color=='black'){
        link=linkBlack
      }else if(this.color=='white'){
        link=linkWhite
      }else{
        link=linkRed
      }
      console.log('link',link,this.color,linkBlack);
      window.open(link)
      // https://buy.stripe.com/test_eVa5nPg1j1wmfXq5kr?utm_campaign=black70a
    },
    getSwiper() {
      this.swiper = new Swiper("#swiper1", {
        // width: window.innerWidth,
        breakpoints: {
          683: {
            slidesPerView: 2.5,
            spaceBetween: 10,
            //  loop: true,
             loopAdditionalSlides: 5,
          },
        },
        slidesPerView: 3,
        spaceBetween: 10,
        grabCursor: true,
        updateOnImagesReady: true,
        pdateOnWindowResize: false,
        // centeredSlides: true,
        // slideToClickedSlide: true,
        loop: true,
        // loopAdditionalSlides: 5,
        nested: true ,
        observer: true,
          observeParents: true,
          observeSlideChildren:true,
        // autoplay: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type:'custom',
          // clickable: true,
          paginationClickable: true,
          // renderBullet: function (index, className) {
          // return '<span class="' + className + '">' + (index + 1) + '</span>';
          renderCustom: (swiper, current, total)=>{
          // return current + ' of ' + total;
          var str=''  
          for(let i=1;i<=total;i++){
            this.pic=this.picList[current-1]
            // this.index=current-1
            // console.log(this.index);
            
            var arr=document.querySelectorAll('.circle')
          for(let i=0;i<arr.length;i++){
            if(arr[i].classList.contains("white")){
              arr[i].classList.remove("white")
            } 
           }
           
           console.log(current);
           arr[current-1].classList.add("white")
           this.color=this.colorList[current-1]
            // console.log(this.picList);
            if(i==current){
              str+='<span style="margin-right:10px;margin-left:10px;">'+i+'</span><span style="margin-right:10px;">——</span>'
            }else{
              str+='<span style="margin-right:10px"margin-left:10px;>'+i+'</span>'
            }
          }
          // var str=''
          // if(current)
          return str;
        
        },
          // renderBullet: function (index, className) {
          //   return '<div class="' + className + '"><span></span><i></i></div>';
          // },
        },
        on:{
          click: (item)=>{
            if(this.pic&&item.target.src){
              this.pic=item.target.src
              
            }else{
              this.pic=this.picList[0]
              // this.index=0
            }
            var arr=document.querySelectorAll('.circle')
          for(let i=0;i<arr.length;i++){
            if(arr[i].classList.contains("white")){
              arr[i].classList.remove("white")
            } 
           }
           
            let indexss = this.pic.lastIndexOf("-")
            let indexss2 = this.pic.indexOf(".")
            var resolvess = this.pic.substring(indexss+1, indexss2);
            // console.log(resolvess)
            if(resolvess=='black'){
               arr[0].classList.add("white")
            }else if(resolvess=='white'){
              arr[1].classList.add("white")
            }else{
              arr[2].classList.add("white")
            }
           this.color=resolvess
            // this.index=resolvess-1
            // console.log('你点了Swiper',item.target.src,this.pic,this.index);
            
          }, 
          // tap: (item)=>{
          //   // console.log('你点了Swiper',item.target.src);
          //   this.pic=item.target.src
          // }, 
        },
        // $('#btn1').click(function(){
        //   mySwiper.slidePrev();
        // })
        // $('#btn2').click(function(){
        //   mySwiper.slideNext();
        // })
    });
    },
    // initSwiper() {
    //         var screenWidth = $(window).width();
    //         if (screenWidth < 768 && newSwiper == undefined) {
    //             newSwiper = new Swiper('.swiper-container', {
    //                 loop: true,
    //                 slidesPerView: 1,
    //                 speed: 500
    //             });
    //         } else if (screenWidth > 768 && newSwiper != undefined) {
    //             newSwiper.destroy();
    //             newSwiper = undefined;
    //             $(".swiper-wrapper").removeAttr("style");
    //             $(".swiper-slide").removeAttr("style");
    //         }
    //     },
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("111");
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.el-select-dropdown__item{
  color: #fff;
}
::v-deep .el-select-dropdown{
  background: rgba(31,30,45,1);
}
::v-deep .el-select-dropdown__item.hover, .el-select-dropdown__item:hover{
  background:#343C68
}
.text7,.text9,.text8,.radio,.pngBox{
  display:none;
}
.btnBox{
  display: flex;
  width:100%;
  flex-direction:column;
  /* align-items:center; */
}
.left a{
  text-decoration-line: none;
}

::v-deep .swiper-container-horizontal>.swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    bottom: 170px;
    left: -35%;
    width: 100%;
    color:#fff;
    font-family: Nunito Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
    letter-spacing: 0em;
    /* text-align: left; */

}

.top{
  width: 100%;
  height: 40px;
  background: linear-gradient(90deg, #6E8BFF 0%, #E792FF 100%);
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  font-weight: 600;
  color: rgba(255, 255, 255, 1);
  text-transform: uppercase;
  font-family: Poppins;
  letter-spacing: 2px;
}
.main{
  display:flex;
  flex-direction: column;
  align-items: center;
  /* background: rgba(31, 30, 46, 1); */
  background-image: url(../assets/background.png);
  width: 100%;
  background-size: cover;
  /* height: 1900px; */
  /* height: 2060px; */
}
.logoBox{
  padding-top :66px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.logoBox img{
  width: 122.6px;
  height: 124.97px;
  
}
.logo img{
  border: 2px solid transparent;
  border-radius: 16px;
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  background-image: linear-gradient(to right, #222, #222), linear-gradient(90deg, #D191FF, #578AEF);
}
.logoBox a{
  text-decoration: none;
}
.logoBox .logo,.logoBox .ntv{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Poppins;
  font-size: 33px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0px;
  color: #fff;
}
.logoBox .ntv{
  margin-left: 27px;
}
.logoBox span{
  margin-top: 18px;;
}
.text{
  margin-left: 35px;
  font-size: 47px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: center;
  color: #fff;
  margin-bottom: 60px;
}
.center{
  margin-top: 33px;
  display: flex;
  align-items:flex-start;
}
.styleBox{
  margin-top: 46px;;
  width: 545px;
  margin-left: 85px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* justify-content: center; */
  color: #fff;
}
.text1{
  font-size: 23px;
  font-weight: 600;
  line-height: 36px;
  letter-spacing: 0px;
  text-align: left;
  text-transform: uppercase;
}
.text2{
  /* //styleName: Headline 6; */
    margin-top: 11px;
    font-size: 20px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 0.15000000596046448px;
    text-align: left;
}
.text2 span{
  margin-left: 5px;
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0.15000000596046448px;
  color: #62616D;
  text-align: left;
  text-decoration: line-through;
}
.text3{
  display: none;
  margin-top: 17px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;

}
.el-select{
  display: none;
  /* border: 1px solid #fff; */
  width: 535px;
  height: 52px;
  margin-top: 4px;
  /* opacity: 0; */
}

::v-deep .el-input--suffix .el-input__inner{
  height: 52px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
  border:1px solid rgba(255, 255, 255, 0.1);
}
.text4{
  margin-top:18px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}
.colorBox{
  margin-top:5px;
  display: flex;
  align-items: center;
}
.colorBox .circle{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  margin-right: 10px;
  cursor: pointer;
} 
.colorBox .circle .color{
  width: 30px;
  height: 30px;
  /* border: 1px solid rgba(255, 255, 255, 0.1); */
  border-radius: 50%;
}
.white{
  border: 1px solid rgba(255, 255, 255, 1) !important;
}
.text5{
  margin-top:18px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;

}
.count{
  margin-top: 8px;
}
.el-input-number{
  width: 137px;
  height: 52px;
}
::v-deep .count .el-input__inner{
  width: 137px;
  height: 52px;
  font-size: 16px;
  background: transparent;
  color: #fff !important;
  border: 1px solid rgba(255, 255, 255, 0.1);
}
 ::v-deep .count .el-input-number__decrease,::v-deep .count .el-input-number__increase{
  background: transparent;
  color: #fff !important;
  height: 52px;
  line-height: 52px;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.10000000149011612px;

  /* text-align: center; */
  border: none;
}
::v-deep .count .el-input-number__decrease{
  text-align: right;
}
::v-deep .count .el-input-number__increase{
  text-align: left;
}
.btn{
  margin-top:18px;
}
.btn .el-button{
  width:534px;
  height:52px;
  text-align: center;
  background: rgba(106, 139, 255, 1);
  border:none;
  color:#fff;
  text-decoration: none;
  font-size: 20px;
  /* padding:10px 80px; */
  margin-top: 10px;
  margin-bottom: 20px;
  display: inline-block;
  border-radius: 5px;
  animation: pulse-animation 0.9s infinite;
  position: relative;
}
.btn .el-button:hover,.btn .el-button:active{
  background: rgba(106, 139, 255, 0.3);
}
@keyframes pulse-animation{
  0%{
    box-shadow: 0 0 0 0px rgba(249, 246, 246, 0.5);
  }
  100%{
    box-shadow: 0 0 0 16px rgba(0,0,0,0);
  }
}
::v-deep .des .el-collapse-item__header{
  width: 534px;
  height: 70px;
  border:none !important;
  border-bottom:1px solid rgba(255, 255, 255, 0.1);
  background: transparent;
  color:#fff;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;

}
.el-collapse-item:last-child{
  width: 534px;
  background: transparent !important;
}
::v-deep .des .el-collapse-item__wrap{
  background: transparent;
  border-bottom:1px solid rgba(255, 255, 255, 0.1);
}
::v-deep .el-collapse-item__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.5px;
  text-align: left;
  color:#fff;
  /* border-bottom:1px solid rgba(255, 255, 255, 0.1); */
}
.des .el-collapse{
  border-bottom:1px solid rgba(255, 255, 255, 0.1);
}
.iconBox{
  margin-top: 30px;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;

}
.iconBox img{
  margin-left: 20px;
  width: 20px;
  height: 20px;
}
.imgBox{
  width: 720px;
  /* margin-top:60px; */
  /* height: 662px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.bigImg{
  width: 720px;
  height: 662px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}
.bigImg img{
  /* padding-left: 35px; */
  /* padding-top: 100px; */
  width: 560px;
  height: 560px;
  border-radius:20px;
}
.swiperBox{
  margin-top:20px;
  position: relative;
  width: 500px;
  height: 150px;
}
#swiper1{
  width:100%;
  /* height:100%; */
}
.swiper-left{
  position: absolute;
  bottom:65px;
  left: -40px;
  width:14px;
  height:50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(95, 91, 132, 1);
  cursor: pointer;
}
.swiper-right{
  position: absolute;
  bottom:65px;
  right: -40px;
  width:14px;
  height:50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(95, 91, 132, 1);
  cursor: pointer;
}
.swiper-left img,.swiper-right img{
  width:3px;
  height:6px;
}
.mask img{
  width:100%;
  height: 100%;
  border-radius:20px;
}
.swiperBox .swiper-slide{
  position: relative;
   height: 25%;
   width:25%;
   /* padding: 36px 22px; */
   background-repeat: no-repeat;
   background-size: contain;
    /* background-color: #F7F8FA; */
}
.el-button{
    z-index: 2;
}
.message{
  position: relative;
  margin-top:180px;
  width: 1280px;
  height: 287px;
  background: rgba(106, 139, 255, 1);
  border-radius: 44px;

}
.message  .left{
  width: 404px;
  height: 287px;
  color:#fff;
  margin-top:42px;
  margin-left:88px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.message .right img{
  position: absolute;
  top:-74px;
  right: 232px;
  width: 314px;
  height: 277px;
}
.message .txt1{
  font-size: 38px;
  font-weight: 600;
  line-height: 38px;
  letter-spacing: 0px;
  text-align: left;
}
.message .txt2{
  height: 66px;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.25px;
  text-align: left;

}
::v-deep .left .el-input--suffix .el-input__inner{
  width: 392px;
  height: 55px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
  border: 1px solid #C3D1FF;
  color:#fff;
} 
::v-deep .el-input__inner::placeholder {
  color:#E1E8FF;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.10000000149011612px;
  text-align: left;
}
.left .el-button{
  cursor: pointer;
  width: 260px;
  border-radius: 10px;
  font-size: 18px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0px;
  text-align: center;
  text-transform: uppercase;
  color: rgba(106, 139, 255, 1);
  animation: pulse-animation 0.9s infinite;
  position: relative;
}
.left .el-button:hover,.left .el-button:active{
  background: #31375E;
  border:#31375E;
  color:#fff;
}
.bottom{
  margin-top:90px;
  padding-top:60px;
  background: #1B1F23;
  width: 100%;
  height: 372px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
}
.end{
  width: 155px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 42px;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: 0px;
  text-align: left;
}
.end .logo img{
  width: 155px;
  height: 158px;
}
.end span{
  margin-top: 12px;
}
.end2{
  margin-left: 41px;
  margin-bottom: 30px;
  width: 474px;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.25px;
  text-align: left;
}
footer{
  height: 45px;
  background: linear-gradient(90deg, #6E8BFF 0%, #E792FF 100%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 85px;
}
.reserved{
  font-size: 16px;
  font-weight: 500;
  line-height: 30px;
  letter-spacing: 0.15000000596046448px;
  text-align: left;
  color:#fff;
}

.Box,.Box img{
  height: 24px;
  width: 313px;
}
@media screen and (min-width:1440px){
  /* .main{
    height:2060px;
  } */
}
@media screen and (max-width:1440px) {
  .message{
    width:80%
  }
  .message .right img{
    width: 214px;
    height: 177px;
    right:102px;
  }
  .center{
    width:100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
  }
  .imgBox{
    width: 500px;
  }
  .bigImg{
    width: 100%;
    height: 500px;
  }
  .bigImg img{
    width: 400px;
    height: 400px;
  }
  .swiperBox{
    margin-top:20px;
    width: 80%;
  }
  .swiper-left{
    bottom:75px;
    left:-30px;
  }
  .swiper-right{
    bottom:75px;
    right:-30px;
  }
  .styleBox{
    width: 500px;
    margin-left: 10px;
  }
.btn .el-button,.text1,.text2,.text3,.text4,.colorBox,.text5,.count,.btn,.select,.des,.iconBox{
    width: 98%;
  }
::v-deep .el-select,::v-deep .des .el-collapse-item__header,::v-deep .el-collapse-item:last-child{
  width: 98%;
    
  }
  /* .main{
    height:1960px
  } */
}
@media screen and (max-width:912px) {
  .message .right img{
    width: 150px;
    height: 127px;
    right:58px;
    top:-62px;
  }
  .message .left{
    margin-left:44px;
  }
  .imgBox{
    width:350px;
  }
  .bigImg{
    width:350px;
    height:350px
  }
  .bigImg img{
    width: 300px;
    height: 300px;
  }
  .swiper-right,.swiper-left{
    bottom:93px;
  }
  .styleBox{
    width:50%;
    margin-left:20px;
    margin-top:-10px;
  }
  .center{
    margin-top:63px;
  }
  .text1{
    font-size:20px
  }
  .swiperBox{
    margin-top:20px;
  }
  footer{
    padding: 0 5px;
  }
}
@media screen and (max-width:683px) {
  .swiper-pagination{
    display:none;
  }
  .center{
    margin-top:30px;
    flex-direction: column;
    align-items: center;
    width:90%;
    justify-content: center;
    /* margin:0 20px; */
  }
  .imgBox{
    width: 100%;
  }
  .bigImg{
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 100%; 

  }
  .bigImg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    bottom: 0px;
  }
  .swiper-left,.swiper-right{
    display:none;
  }
  .swiperBox{
    width:100%;
    height: 100%;
    margin-top: 10px;
  }
  .styleBox{
    margin-top:20px;
    width:100%;
  }
  .btn .el-button,.text1,.text2,.text3,.text4,.colorBox,.text5,.count,.btn,.select,.des,.iconBox{
    width: 100%;
  }
::v-deep .el-select,::v-deep .des .el-collapse-item__header,::v-deep .el-collapse-item:last-child{
  width: 100%;
    
  }
  .text1{
    font-size: 26px;
    font-weight: 600;
    line-height: 30spx;
    letter-spacing: 0px;
    text-align: left;
    text-transform: none;

  }
  .text2 span{
    /* display: none; */
  }
  .text2{
    width:153px;
    height: 31.4px;
    border-radius: 6.56px;
    display:flex;
    align-items: center;
    justify-content: center;
    background: rgba(106, 139, 255, 1);
  }
  .text7{
    margin-top:20px;
    display: block;
    font-size: 17px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.266400009393692px;
    text-align: left;
  }
  .text8{
    display: block;
    margin-top:20px;
    font-size: 19px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0.40412622690200806px;
    text-align: left;
  }
  .text3,.select,.text5,.des,.iconBox{
    display: none;
  }
  /* .main{
    height: 3230px;
  } */
  .colorBox .circle,.colorBox .circle .color{
    border-radius: 0px;
  }
  .text9{
    display: none;
    margin-top:20px;
    font-size: 18px;
    font-weight: 700;
    line-height: 17px;
    letter-spacing: 0.40412622690200806px;
    text-align: left;

  }
  .radio{
    display: none;
  }
  .btn .el-button{
    border-radius:8px;
  }
  ::v-deep .el-radio-button__inner{
    width:60px;
    height:40px;
    display:flex;
    align-items:center;
    justify-content: center;
    background: transparent;
    border: 0.81px solid linear-gradient(90deg, #6E8BFF 0%, #E792FF 100%);
    border-image-source: linear-gradient(90deg, #6E8BFF 0%, #E792FF 100%);
    border-image-slice:1;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0.40412622690200806px;
    text-align: left;
    color:#fff;

  }
  .el-radio-group{
    margin:10px 10px 0 0;
  }
  ::v-deep .el-radio-button__orig-radio:checked+.el-radio-button__inner{
    background-color: #6A8BFF;
  }
  .text4{
    text-transform: uppercase;
    font-size:18px;
    line-height:26px;
  }
  .btnBox{
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }
  .btnBox .btn{
    width:48%;
    margin-left:12px;
  }
  .btnBox .count{
    width:40%;
  }
  .el-input-number{
    width:100%;
  }
  ::v-deep .count .el-input__inner{
    width:100%;
  }
  ::v-deep .count .el-input-number__decrease {
    text-align: center;
     width: 25%;
  }
  /* ::v-deep .count .el-input-number__increase{
   
    background: #5F5B84;
  } */
  ::v-deep .count .el-input-number__decrease,::v-deep .count .el-input-number__increase{
    background: #5F5B84;
    text-align: center;
    display:flex;
    align-items: center;
    justify-content: center;
     width: 25%;
     top:0px;
  }
  ::v-deep .count .el-input__inner{
    background:#44425C;
  }
  .pngBox{
    display:flex;
  }
  .pngBox .line{
      width: 40.41px;
      height: 40.41px;
      border-radius: 6.47px;
      border: 0.81px solid rgba(106, 139, 255, 1);
      display:flex;
      align-items:center;
      justify-content: center;
      margin-right:13px;
      margin-top:10px;

  }
  .pngBox img{
    width:16.17px;
    height:16.17px;
  }
  .message .right img{
    position: relative;
    /* top:-200px; */
    top:0%;
    right:0%;
    width: 223px;
    height: 196.51px;
  }
  .message{
    /* margin-top:250px; */
    width:90%;
    background: transparent;
    border: none;
    display:flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  }
  .message .left{
    margin:0px;
    margin-top:20px;
    width: 80%;
    height:264px;
    border-radius: 12px;
    padding: 30px 34px 27px 20px;
    background: #6A8BFF;

  }
  .message .txt1{
    width:100%;
  }
  ::v-deep .left .el-input--suffix .el-input__inner {
    width:80%
  }
  .bottom{
    width: 90%;
    margin-top:190px;
    /* height: 325px; */
    flex-direction: column;
    align-items:center;
    background: transparent;
  }
  .end2{
    margin:0;
    width: 100%;
    margin-top: 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.25px;
    text-align: center;

  }
  .end{
    font-size: 42px;
    font-weight: 600;
    line-height: 46px;
    letter-spacing: 0px;
    text-align: left;

  }
  footer{
    height:84px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .Box{
    margin-top:15px;
  }
  .logoBox {
    padding-top:33px;
  }
  .logoBox img{
    width:50.96px;
    height:52.48px;
  }
  .logoBox .logo span,.ntv span{
    font-size: 21px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: left;
  }
  .ntv{
    margin-left:10px;
  }
  .top{
    display: flex;
    /* height:50px; */
    padding:8px 0px;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 2px;
    text-align: center;
  }
  .text{
    margin-bottom:40px;
    font-size: 37px;
  }
}
@media screen and (max-width:506px) {
  .message .el-input{
    margin-top:20px
  }
  .reserved{
    text-align:center;
  }
}
@media screen and (max-width:364px){
  .reserved{
    font-size: 15px;
  }
  .bottom{
    margin-top:220px;
    padding-bottom: 80px
  }
}
@media screen and (max-width:768px) and (min-width:683px){
  .logoBox img{
    width: 70.6px;
    height: 72.97px;
  }
  .logoBox{
    padding-top:33px;
  }
  .cente{
    padding-top:33px;
  }
  .text1,.text2{
    font-size:18px;
    line-height:25px;
  }
}
</style>
